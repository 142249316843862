<template>
  <div>
    <div class="pt-5 pb-0">
      <h2 class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5">معرض الصور</h2>
      <b-container>
        <b-row>
          <b-col md="6" lg="3" v-for="(i, key) in allGalleries" :key="key">
            <event-card :item="i" type="image" @click.native="$router.push({name: 'imageDetails', params: {imageId: i.id}})"/>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import eventCard from '@/components/eventCard'
import homeServices from '@/modules/primaryPages/home/services/home'
export default {
  name: 'eventDetails',
  mounted () {
    core.index()
  },
  components: {
    eventCard
  },
  data () {
    return {
      allGalleries: []
    }
  },
  methods: {
    getImages () {
      homeServices.getAllGallery().then(res => {
        this.allGalleries = res.data.data
      })
    }
  },
  created () {
    this.getImages()
  }
}
</script>

<style>
.photo-container{
  display:grid;
  grid-template-columns: repeat(6,1fr);
  grid-auto-rows:100px 300px;
  grid-gap:20px;
  grid-auto-flow: dense;
}
.gallery-item{
  width:100%;
  height:100%;
  position:relative;
}
.gallery-item .image{
  width:100%;
  height:100%;
  border-radius: 15px;
  overflow:hidden;
}
.gallery-item .image img{
  width:100%;
  height:100%;
  object-fit: cover;
  object-position:50% 50%;
  cursor:pointer;
  transition:.5s ease-in-out;
}
.gallery-item:hover .image img{
  transform:scale(1.5);
}
.gallery-item .text{
  opacity:0;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  color:#fff;
  font-size:25px;
  pointer-events:none;
  z-index:4;
  transition: .3s ease-in-out;
  -webkit-backdrop-filter: blur(5px) saturate(1.8);
  backdrop-filter: blur(5px) saturate(1.8);
}
.gallery-item:hover .text{
  opacity:1;
  animation: move-down .3s linear;
  padding:1em;
  width:100%;
}
.w-1{
  grid-column: span 1;
}
.w-2{
  grid-column: span 2;
}
.w-3{
  grid-column: span 3;
}
.w-4{
  grid-column: span 4;
}
.w-5{
  grid-column: span 5;
}
.w-6{
  grid-column: span 6;
}
.h-1{
  grid-row: span 1;
}
.h-2{
  grid-row: span 2;
}
.h-3{
  grid-row: span 3;
}
.h-4{
  grid-row: span 4;
}
.h-5{
  grid-row: span 5;
}
.h-6{
  grid-row: span 6;
}
@media screen and (max-width:500px){
  .container{
    grid-template-columns: repeat(1,1fr);
  }
  .w-1,.w-2,.w-3,.w-4,.w-5,.w-6{
    grid-column:span 1;
  }
}
@keyframes move-down{
  0%{
    top:10%;
  }
  50%{
    top:35%;
  }
  100%{
    top:50%;
  }
}

</style>
